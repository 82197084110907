import { Shipment } from '@core/models/shipment.model';

export class DividedStockHelper {
  public static setDividedStock(data: Shipment): Shipment {
    const dividedStocks = data?.packingList?.reduce((acc: any, item: any) => {
      let cardHeader = item.productGroup?.code ? `${item.productGroup.code} - ` : '';

      cardHeader += item.product?.name;
      item.boxed_stock_id = item.inboundStock?.boxed_stock_id;

      if (acc[cardHeader]) {
        acc[cardHeader] = [...acc[cardHeader], item];
      } else {
        acc[cardHeader] = [item];
      }

      return acc;
    }, {});

    return { ...data, dividedStocks };
  }
}
